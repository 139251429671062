<template>
  <v-container fluid>
    <!-- HEADER -->
    <div class="text-center section mb-n4">
      <h2 class="h2">Version Logs</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        All system update and version logs
      </p>
    </div>

    <div class="myCard" v-scrollbar>
      <v-timeline align-top>
        <v-timeline-item
          v-for="(val, i) in versionLogs"
          :key="i"
          fill-dot
          color="#00CBA3"
          icon="mdi-post"
          medium
        >
          <template v-slot:opposite>
            <!-- MODIFICATION DATE -->
            <h4 class="mb-n6" v-text="'Modification Date'" />
            <br />
            <span>{{ `${val.ModificationDate[0]} To ${val.ModificationDate[1]}` }}</span
            ><br />
            <!-- DEPLOYMENT DATE -->
            <h4 class="mb-0" v-text="'Deployment Date'" />
            <span v-text="val.DeploymentDate" />
            <br />

            <!-- PRESENTED DATE -->
            <h4 class="mb-0" v-text="'Presented Date'" />
            <span v-text="val.PresentedDate" />
            <br />
          </template>
          <v-card color="#DF6464" class="elevation-2">
            <v-card-title class="text-h5" style="color: white; font-weight: bold">
              {{ `Version ${val.Version}` }}
              <v-spacer />

              <v-icon
                @click="AddRevisionDetails(val.id, val.RevisionDetails)"
                color="white"
                style="cursor: pointer"
                >mdi-archive-plus</v-icon
              >
            </v-card-title>

            <!-- REVISION LIST -->
            <div v-if="val.RevisionDetails">
              <v-list-item
                two-line
                class="white text--primary"
                v-for="(rev, x) in val.RevisionDetails"
                :key="x"
              >
                <v-icon @click="ShowImage(rev)" class="mr-4" style="cursor: pointer"
                  >mdi-image-outline</v-icon
                >
                <v-list-item-content>
                  <v-list-item-title>{{ rev.Title }} </v-list-item-title>
                  <v-list-item-subtitle>{{ rev.Description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- NO RECORD -->
            <div v-else class="white text--primary pa-5 text-center">
              <span style="color: red; font-size: 20px; font-weight: bold"
                >No Record Found</span
              >
            </div>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <div
        class="text-center mt-12"
        style="color: red; font-size: 30px"
        v-if="!versionLogs.length"
      >
        <span v-text="'No Record Found!'" />
      </div>
    </div>

    <v-fab-transition>
      <v-btn
        @click="addRecordDialog = !addRecordDialog"
        fab
        medium
        bottom
        color="#00CBA3"
        class="v-btn--example mt-n5 float-right"
      >
        <v-icon color="white">mdi-plus-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- INSERT DIALOG -->
    <v-dialog v-model="addRecordDialog" max-width="500px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title> <v-icon>mdi-pen-plus</v-icon> Add New Version </v-card-title>
        <v-divider class="mb-5" />
        <!-- VERSION -->
        <span style="color: grey"> Version<span style="color: red" v-text="'*'" /></span>
        <v-text-field
          v-model="toInsert.Version"
          class="mb-n4"
          color="#EF3C3C"
          style="width: 110px"
          outlined
          dense
        />

        <!-- MODIFICATION DATE -->
        <span style="color: grey">
          Modification Date<span style="color: red" v-text="'*'"
        /></span>
        <v-menu
          class="text-center"
          content-class="elevation-0"
          :close-on-content-click="false"
          transition="scale-transition"
          offet-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toInsert.ModificationDate"
              v-bind="attrs"
              v-on="on"
              class="mb-n4"
              color="red"
              outlined
              dense
            />
          </template>
          <v-date-picker range v-model="toInsert.ModificationDate" color="#df6464" />
        </v-menu>

        <!-- DEPLOYMENT DATE -->
        <span style="color: grey">
          Deployment Date<span style="color: red" v-text="'*'"
        /></span>
        <v-menu
          content-class="elevation-0"
          class="text-center"
          :close-oncontent-click="false"
          transition="scale-transition"
          offet-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toInsert.DeploymentDate"
              v-bind="attrs"
              v-on="on"
              class="mb-n4"
              color="red"
              outlined
              dense
            />
          </template>
          <v-date-picker v-model="toInsert.DeploymentDate" color="#df6464" />
        </v-menu>

        <!-- PRESENTED DATE -->
        <span style="color: grey">
          Presented Date<span style="color: red" v-text="'*'"
        /></span>
        <v-menu
          content-class="elevation-0"
          class="text-center"
          :close-oncontent-click="false"
          transition="scale-transition"
          offet-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toInsert.PresentedDate"
              v-bind="attrs"
              v-on="on"
              class="mb-n4"
              color="red"
              outlined
              dense
            />
          </template>
          <v-date-picker v-model="toInsert.PresentedDate" color="#df6464" />
        </v-menu>
        <v-divider class="mb-4 mt-4" />
        <v-btn @click="AddRecord(toInsert)" color="#00CBA3" style="color: white" block
          >Submit</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- REVISION DIALOOG -->
    <v-dialog v-model="revisionDialog" max-width="500px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title> <v-icon>mdi-pen-plus</v-icon> Add New Revision </v-card-title>
        <v-divider class="mb-5" />

        <!-- TITLE -->
        <span style="color: grey"> Title<span style="color: red" v-text="'*'" /></span>
        <v-text-field
          v-model="toUpdate.toAdd.Title"
          class="mb-n4"
          color="#EF3C3C"
          outlined
          dense
        />

        <!-- DESCRIPTION -->
        <span style="color: grey">
          Description<span style="color: red" v-text="'*'"
        /></span>
        <v-text-field
          v-model="toUpdate.toAdd.Description"
          class="mb-n4"
          color="#EF3C3C"
          outlined
          dense
        />

        <!-- ATTACHMENT -->
        <span style="color: grey">
          Attachment<span style="color: red" v-text="'*'"
        /></span>
        <v-file-input
          @change="toUpdate.toAdd.Attachment = files.name"
          v-model="files"
          color="#EF3C3C"
          outlined
          dense
        />
        <v-divider class="mb-4 mt-4" />

        <!-- ACTION -->
        <v-btn @click="UpdateRevision(toUpdate)" color="success" block>Submit</v-btn>
      </v-card>
    </v-dialog>

    <!-- IMAGE DIALOG -->
    <v-dialog v-model="imageDialog" max-width="1040px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title primary-title> {{ imageObj.Title }} </v-card-title>
        <v-card-subtitle>{{ imageObj.Description }}</v-card-subtitle>
        <v-divider class="mb-3" />
        <v-img
          max-height="500"
          max-width="1000"
          :src="`https://s3.us-east-2.amazonaws.com/inquiry.files/${imageObj.Attachment}`"
        ></v-img>

        <!-- @error="changeImage" -->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import VersionLogsTable from "../../functions/httpRequest/ExternalConnection/VersionLogsTable";
import AWSAttachment from "../../functions/Attachment";
import others from "../../functions/alert";

export default {
  data() {
    return {
      addRecordDialog: false,
      revisionDialog: false,
      imageDialog: false,
      fab: false,
      files: null,
      imageObj: {},
      toInsert: { ModificationDate: [] },
      toUpdate: { toAdd: {} },
      versionLogs: [],
    };
  },
  mounted() {
    this.GetAllRecords();
  },
  methods: {
    ShowImage(val) {
      this.imageDialog = !this.imageDialog;
      this.imageObj = { ...val };
    },
    //************************************************//
    // UPDATE REVISION DATABASE IN VERSIONLOGS TABLE  //
    //************************************************//

    async UpdateRevision({ ...val }) {
      const path = `VersionLogs/${moment().format("YYYY/MM/DD")}`;
      AWSAttachment.upload(
        "VersionLogs",
        [this.files],
        async (filename) => {
          val.toAdd.Attachment = `${path}/${filename[0]}`;
          val.RevisionDetails.push(val.toAdd);
          val.RevisionDetails = JSON.stringify(val.RevisionDetails);
          delete val.toAdd;
          
          await VersionLogsTable.Update(val).then(() => {
            this.GetAllRecords();
            this.toUpdate = { toAdd: {} };
            this.revisionDialog = !this.revisionDialog;
            this.$toast.success("Save Successfully", others.alert);
          });
        },
        () => console.log("Done")
      );
    },

    //******************************************//
    //  ADD REVISION DETAILS VERSIONLOGS TABLE  //
    //******************************************//

    AddRevisionDetails(id, details) {
      this.toUpdate.id = id;
      this.toUpdate.RevisionDetails = details || [];
      this.revisionDialog = !this.revisionDialog;
    },

    //****************************************//
    // INSERT RECORD INTO VERSIONLOGS TABLE   //
    //****************************************//

    AddRecord({ ...val }) {
      val.versionName = 'aws'
      val.ModificationDate = JSON.stringify(val.ModificationDate);
      VersionLogsTable.Post(val).then(() => {
        this.GetAllRecords();
        this.$toast.success("Save Successfully", others.alert);
        this.addRecordDialog = !this.addRecordDialog;

        //!
        console.log("Add VersionLog");
        console.log(val,"val");
      });
    },

    //*****************************************//
    // GET ALL RECORD INTO VERSIONLOGS TABLE   //
    //*****************************************//

    GetAllRecords() {
      VersionLogsTable.Get().then((res) => {
        const data = res.data.filter((rec) => {
          if (rec.versionName == "aws") return rec;
        });
        // var lastData = data.length - 1;
        // console.log(data,"ln351 data");
        // console.log(data[lastData].Version);SSSSS
        this.versionLogs = data.length
          ? data.map((el) => {
              return {
                ...el,
                RevisionDetails: el.RevisionDetails
                  ? JSON.parse(el.RevisionDetails)
                  : null,
                ModificationDate: JSON.parse(el.ModificationDate),
                DeploymentDate: moment(el.DeploymentDate).format("YYYY-MM-DD"),
                PresentedDate: moment(el.PresentedDate).format("YYYY-MM-DD"),
              };
            })
          : [];
      });
    },
  },
};
</script>
<style lang="scss">
.myCard {
  max-height: calc(106.5vh - 290px);
  width: 100%;
  padding: 10px;
}
</style>
